import React, { FunctionComponent } from "react";
import { RouteComponentProps } from "@reach/router";

import Layout from "../components/Layout";

const NotFoundPage: FunctionComponent<RouteComponentProps> = () => (
  <Layout>
    <h1>NOT FOUND</h1>
  </Layout>
);

export default NotFoundPage;
